<script lang="ts">
import { RouterView } from 'vue-router';

export default {
    name: 'vueMain',
    components: {
        RouterView
    }
}
</script>

<template>
    <main>
        <RouterView />
    </main>
</template>