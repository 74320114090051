<script lang="ts">
import sectionComponent from './components/section-component.vue';
import menuComponent from './components/menu-component.vue';
import { menus } from './router/menu';
import githubIcon from './assets/icons/github.svg';
import emailIcon from './assets/icons/email.svg';

export default {
    name: 'vueFooter',
    components: {
        sectionComponent,
        menuComponent
    },
    data() {
        return {
            menus,
            githubIcon,
            emailIcon
        }
    }
}
</script>

<template>
    <footer>
        <div class="container">
            <sectionComponent sectionId="footer-menu">
                <nav class="navigation">
                    <menuComponent :menu="menus.footer" :menuClasses="['menu']" />
                </nav>
                <ul class="socials">
                    <li>
                        <a href="https://github.com/davidS2-2424/" target="_blank">
                            <img class="mask-icon" :style="`--mask-icon-url: url('${githubIcon}')`" width="32"
                                height="32" :src="githubIcon" alt="Github" />
                            <span>GitHub</span>
                        </a>
                    </li>
                    <li>
                        <a href="mailto:davids2.2424@gmail.com">
                            <img class="mask-icon" :style="`--mask-icon-url: url('${emailIcon}')`" width="32"
                                height="32" :src="emailIcon" alt="Github" />
                            <span>davids2.2424@gmail.com</span>
                        </a>
                    </li>
                </ul>
            </sectionComponent>
        </div>
    </footer>
</template>

<style lang="scss">
@use "sass:map";
@import '@/styles/colors';
@import '@/styles/variables';
@import '@/styles/mixins';

footer {
    position: relative;
    background: var(--black);
    z-index: 1;
    transition: $transition-base;

    #footer-menu {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        .navigation {
            .menu {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    position: relative;
                    height: 100%;
                    padding-right: 0.5rem;
                    margin-right: 0.5rem;
                    border-right: 2px solid var(--white-500);

                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }

                    &:hover {
                        >.submenu {
                            transform: translateY(0);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 1rem;
                        font-family: $font-family-headings;
                        font-size: map.get($heading-font-sizes, "h5");

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: map.get($colors, "full-black");
                            border-radius: 0.25rem;
                            opacity: 0;
                            transition: $transition-base;
                        }

                        &::after {
                            display: none;
                        }

                        &:hover {
                            span {
                                opacity: 0.8;
                                transition: $transition-base;
                            }
                        }

                        &.router-link-active {
                            &::before {
                                opacity: 1;
                            }

                            span {
                                transition: $transition-base;
                                @include dynamic-color(var(--color-gradient), 3s);
                            }
                        }

                        span {
                            position: relative;
                            z-index: 1;
                            transition: none;
                        }
                    }
                }
            }
        }

        .socials {
            display: flex;
            gap: 2rem;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                a {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    &::after {
                        left: 2.5rem;
                        width: calc(100% - 2.5rem);
                    }

                    .mask-icon {
                        width: 2rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    footer {
        #footer-menu {
            flex-direction: column;
            align-items: center;
            gap: 1.5rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    footer {
        #footer-menu {

            .navigation .menu,
            .socials {
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                li {
                    padding-right: 0;
                    margin-right: 0;
                    border-right: none;
                }
            }
        }
    }
}
</style>