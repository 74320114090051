import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { menus } from './menu'
import { menuItemType } from '../types'

const routes = [...menus.header, ...menus.footer, ...menus.abstract]

function createRoutes(routes: menuItemType[]) {
  const result: menuItemType[] = []

  function handleRoutes(routes: menuItemType[]) {
    for (const route of routes) {
      if (route.submenu) {
        handleRoutes(route.submenu)
      }

      result.push({
        path: route.path,
        name: route.name,
        component: route.component
      })
    }
  }

  handleRoutes(routes)

  return result as RouteRecordRaw[]
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: createRoutes(routes)
})

router.beforeEach((to, _from, next) => {
  if (to.name) {
    document.title = to.name as string
  } else {
    document.title = 'Fehler'
  }
  next()
})

export default router
