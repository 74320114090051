<script lang="ts">
import { RouterLink } from 'vue-router';

export default {
    name: 'menuComponent',
    props: {
        menu: {
            type: Object
        },
        menuClasses: {
            type: Array
        }
    },
    components: {
        RouterLink
    }
}
</script>

<template>
    <ul :class="menuClasses">
        <li v-for="item in menu" :key="item.path" :class="item.submenu ? 'has-submenu' : ''">
            <RouterLink :to="item.path">
                <span>{{ item.name }}</span>
            </RouterLink>
            <menuComponent v-if="item.submenu && item.submenu.length" :menu="item.submenu" :menuClasses="['submenu']" />
        </li>
    </ul>
</template>