<script lang="ts">
export default {
    name: 'sectionComponent',
    props: {
        sectionId: {
            type: String,
            default: ''
        }
    }
}
</script>

<template>
    <section :id="sectionId">
        <slot></slot>
    </section>
</template>

<style lang="scss" scoped>
section {
    padding: 1rem 0;
}
</style>