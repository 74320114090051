import HomeView from '../views/HomeView.vue'
import ImpressumView from '../views/ImpressumView.vue'
import DatenschutzView from '../views/DatenschutzView.vue'
import NotFound from '../views/NotFound.vue'
import { menuType } from '../types'

const headerMenu = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    submenu: [
      {
        path: '/home1',
        name: 'Untermenüpunkt 1',
        component: HomeView
      },
      {
        path: '/home2',
        name: 'Untermenüpunkt 2',
        component: HomeView
      },
      {
        path: '/home3',
        name: 'Untermenüpunkt 3',
        component: HomeView
      },
      {
        path: '/home4',
        name: 'Untermenüpunkt 4',
        component: HomeView,
        submenu: [
          {
            path: '/home1',
            name: 'Untermenüpunkt 1',
            component: HomeView
          },
          {
            path: '/home2',
            name: 'Untermenüpunkt 2',
            component: HomeView
          },
          {
            path: '/home3',
            name: 'Untermenüpunkt 3',
            component: HomeView
          },
          {
            path: '/home4',
            name: 'Untermenüpunkt 4',
            component: HomeView
          }
        ]
      }
    ]
  },
  {
    path: '/test1',
    name: 'Test 1',
    component: HomeView
  },
  {
    path: '/test2',
    name: 'Test 2',
    component: HomeView
  },
  {
    path: '/test3',
    name: 'Test 3',
    component: HomeView
  },
  {
    path: '/test4',
    name: 'Test 4',
    component: HomeView
  },
  {
    path: '/test5',
    name: 'Test 5',
    component: HomeView
  }
]

const footerMenu = [
  {
    path: '/impressum',
    name: 'Impressum',
    component: ImpressumView
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: DatenschutzView
  }
]

const abstract = [
  {
    path: '/404',
    name: 'Fehler',
    component: NotFound
  }
]

export const menus: menuType = {
  header: headerMenu,
  footer: footerMenu,
  abstract: abstract
}
