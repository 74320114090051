<script lang="ts">
import { RouterLink } from 'vue-router';
import sectionComponent from './components/section-component.vue';
import menuComponent from './components/menu-component.vue';
import { menus } from './router/menu';
import logo from './assets/icons/logo.svg';
import menuIcon from './assets/icons/menu.svg';
import closeIcon from './assets/icons/close.svg';

export default {
    name: 'vueHeader',
    components: {
        RouterLink,
        sectionComponent,
        menuComponent
    },
    data() {
        return {
            menus,
            scrolled: false,
            menuVisible: false,
            focus: false,
            logo,
            menuIcon,
            closeIcon
        }
    },
    methods: {
        toggleFocus() {
            if (window.innerWidth <= 1199.98) {
                const focusableElementsInsideNav = document.querySelectorAll("header #top .navigation a, header #top .navigation button");
                this.focus = !this.focus;

                for (const element of focusableElementsInsideNav) {
                    this.focus
                        ? element.setAttribute("tabindex", "-1")
                        : element.setAttribute("tabindex", "0")
                }
            }
        },
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
            this.toggleFocus();
        },
        handleScroll() {
            this.scrolled = window.scrollY > 0;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.toggleFocus);
        this.toggleFocus();
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.toggleFocus);
    }
}
</script>

<template>
    <header :class="scrolled ? 'scrolled' : ''">
        <div class="container">
            <sectionComponent sectionId="top">
                <RouterLink to="/" class="logo">
                    <img width="40" height="40" :src="logo" alt="Logo" />
                </RouterLink>
                <button class="navigation-toggler" @click="toggleMenu">
                    <img class="mask-icon" :style="`--mask-icon-url: url('${menuVisible ? closeIcon : menuIcon}')`"
                        width="24" height="24" :src="menuVisible ? closeIcon : menuIcon" alt="Toggle Menu" />
                </button>
                <nav :class="['navigation', menuVisible ? 'visible' : '']">
                    <menuComponent :menu="menus.header" :menuClasses="['menu']" />
                </nav>
            </sectionComponent>
            <sectionComponent sectionId="bottom">
                <picture>
                    <img src="https://szm-media.sueddeutsche.de/image/szm/93cb43622718d7e1f45968742dd60ae7/640/image.jpeg"
                        alt="Asf" />
                </picture>
            </sectionComponent>
        </div>
    </header>
</template>

<style lang="scss">
@use "sass:map";
@import '@/styles/colors';
@import '@/styles/variables';
@import '@/styles/mixins';

header {
    position: relative;
    padding-top: var(--header-height);

    &.scrolled {
        #top {
            &::before {
                background: var(--black);
            }

            .submenu {
                &::after {
                    background: var(--black);
                }
            }
        }
    }

    #top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 50%;
        width: var(--container-max-width);
        height: var(--header-height);
        transform: translateX(-50%);
        transition: $transition-base;
        z-index: 3;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc((100vw - var(--container-max-width)) / -2);
            width: 100vw;
            height: 100%;
            transition: $transition-base;
        }

        &:has(li.has-submenu:hover) {
            &::before {
                background: var(--black);
            }

            .submenu {
                &::after {
                    background: var(--black);
                }
            }
        }

        .logo {
            position: relative;
            z-index: 1;

            &::after {
                display: none;
            }
        }

        .navigation {
            height: 100%;

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
            }

            .menu {
                display: flex;
                height: 100%;

                li {
                    position: relative;
                    height: 100%;
                    padding-right: 0.5rem;
                    margin-right: 0.5rem;
                    border-right: 2px solid var(--white-500);

                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }

                    &:hover {
                        >.submenu {
                            transform: translateY(0);
                            opacity: 1;
                            pointer-events: all;
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        padding: 0 1rem;
                        font-family: $font-family-headings;
                        font-size: map.get($heading-font-sizes, "h5");

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: map.get($colors, "full-black");
                            border-radius: 0.25rem;
                            opacity: 0;
                            transition: $transition-base;
                        }

                        &::after {
                            display: none;
                        }

                        &:hover {
                            span {
                                opacity: 0.8;
                                transition: $transition-base;
                            }
                        }

                        &.router-link-active {
                            &::before {
                                opacity: 1;
                            }

                            span {
                                transition: $transition-base;
                                @include dynamic-color(var(--color-gradient), 3s);
                            }
                        }

                        span {
                            position: relative;
                            z-index: 1;
                            transition: none;
                        }
                    }

                    .submenu {
                        display: flex;
                        position: fixed;
                        top: var(--header-height);
                        left: 0;
                        width: 100%;
                        padding: 1rem 0;
                        transform: translateY(50%);
                        border-radius: 0 0 0.25rem 0.25rem;
                        white-space: nowrap;
                        opacity: 0;
                        pointer-events: none;
                        transition: $transition-base;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: -1rem;
                            left: 0;
                            width: 100%;
                            height: calc(1rem - 0.125rem);
                            border-bottom: 0.125rem solid var(--white-500);
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: calc((100vw - var(--container-max-width)) / -2);
                            width: 100vw;
                            height: 100%;
                            z-index: -1;
                            transition: $transition-base;
                        }

                        li {
                            position: static;

                            .submenu {
                                position: absolute;
                                top: 100%;
                                width: var(--container-max-width);
                            }
                        }
                    }
                }
            }
        }

        .navigation-toggler {
            display: none;
        }
    }
}

@include media-breakpoint-down(xl) {
    header {
        #top {
            .navigation {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: fixed;
                top: 0;
                right: calc((100vw - var(--container-max-width)) / -2);
                width: 100vw;
                height: 100vh; // safari can't do svh
                height: 100svh;
                background-color: var(--black-800);
                backdrop-filter: blur(2px);
                opacity: 0;
                pointer-events: none;
                z-index: 2;
                transition: $transition-base;

                &.visible {
                    opacity: 1;
                    pointer-events: all;

                    .menu {
                        transform: translateX(0);
                    }
                }

                .menu {
                    flex-direction: column;
                    justify-content: center;
                    gap: 0.5rem;
                    padding: 1rem calc((100vw - var(--container-max-width)) / 2) 1rem 4rem;
                    transform: translateX(100%);
                    background-color: var(--black);
                    transition: $transition-base;

                    li {
                        height: auto;
                        margin-right: 0;
                        padding-right: 0;
                        border-right: none;

                        a {
                            height: auto;
                        }

                        .submenu {
                            flex-direction: column;
                            gap: 0.5rem;
                            position: relative;
                            top: unset;
                            left: unset;
                            padding: 0.5rem 0 0;
                            margin-left: 1rem;
                            transform: translateY(0);
                            border-left: 1px solid var(--white-500);
                            border-radius: 0;
                            white-space: normal;
                            opacity: 1;
                            pointer-events: all;

                            &::before,
                            &::after {
                                display: none;
                            }

                            li {
                                .submenu {
                                    position: relative;
                                    top: unset;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            .navigation-toggler {
                display: block;
                height: 2.5rem;
                padding: 0.5rem;
                background-color: var(--white);
                border: none;
                box-shadow: $box-shadow;
                border-radius: 0.25rem;
                z-index: 3;
                transition: $transition-base;

                &:hover {
                    background-color: var(--white-800);
                }

                .mask-icon {
                    width: auto;
                    height: 100%;
                    @include dynamic-background-color(var(--color-gradient, 3s));
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    header #top .navigation .menu {
        width: 100%;
        padding: 4rem 1.5rem;
        justify-content: normal;
        overflow: auto;
    }
}
</style>