<script lang="ts">
import vueHeader from './header.vue';
import vueMain from './main.vue';
import vueAside from './aside.vue';
import vueFooter from './footer.vue';
import { OverlayScrollbars } from 'overlayscrollbars';
import 'overlayscrollbars/overlayscrollbars.css';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name: 'app',
    components: {
        vueHeader,
        vueMain,
        vueAside,
        vueFooter
    },
    setup() {
        onMounted(() => {
            OverlayScrollbars(document.body, {
                paddingAbsolute: true,
                scrollbars: {
                    autoHide: 'scroll'
                },
            });
        });
    }
});
</script>

<template>
    <vueHeader />

    <vueMain />

    <vueAside />

    <vueFooter />
</template>

<style lang="scss">
@use "sass:map";
@use "sass:color";
@import "@/styles/colors";
@import "@/styles/variables";
@import "@/styles/mixins";

:root {
    @each $name, $color in $colors {
        $r: red($color);
        $g: green($color);
        $b: blue($color);

        --#{$name}-raw: #{$r},
        #{$g},
        #{$b};
        --#{$name}: rgb(var(--#{$name}-raw));

        @for $i from 1 through 9 {
            --#{$name}-#{$i}00: rgba(var(--#{$name}-raw), 0.#{$i});
        }
    }

    @each $breakpoint, $max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint) {
            --container-max-width: calc(#{$max-width} - 3rem);
        }
    }

    --color-gradient: linear-gradient(to right, var(--primary-color), var(--secondary-color), var(--primary-color), var(--secondary-color));
    --background-color-gradient: linear-gradient(to right bottom, var(--primary-background-color), var(--secondary-background-color), var(--primary-background-color), var(--secondary-background-color));
    --header-height: 4.5rem;
}
</style>