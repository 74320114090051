<script lang="ts">
import sectionComponent from './components/section-component.vue';
import moonIcon from './assets/icons/moon.svg';
import sunIcon from './assets/icons/sun.svg';
import arrowIcon from './assets/icons/arrow.svg';

export default {
    name: 'vueAside',
    components: {
        sectionComponent
    },
    data() {
        return {
            darkModeActive: true,
            asideActive: false,
            focus: false,
            sunIcon,
            moonIcon,
            arrowIcon
        }
    },
    methods: {
        toggleFocus() {
            if (window.innerWidth <= 1199.98) {
                const focusableElementsInsideAside = document.querySelectorAll("aside #aside-menu .functions a, aside #aside-menu .functions button");
                this.focus = !this.focus;

                for (const element of focusableElementsInsideAside) {
                    this.focus
                        ? element.setAttribute("tabindex", "-1")
                        : element.setAttribute("tabindex", "0")
                }
            }
        },
        changeColor(html: HTMLElement, htmlStyles: CSSStyleDeclaration, color: string) {
            const propertyName = `--${color}-raw`;
            const propertyStyle = htmlStyles.getPropertyValue(propertyName);
            const propertyRgbValues = propertyStyle.match(/\d+/g);

            if (propertyRgbValues) {
                const [r, g, b] = propertyRgbValues.map(Number);
                html.style.setProperty(propertyName, `${255 - r}, ${255 - g}, ${255 - b}`);
            }
        },
        changeColorMode() {
            this.darkModeActive = !this.darkModeActive;

            const colors = [
                "primary-color",
                "secondary-color",
                "primary-background-color",
                "secondary-background-color",
                "white",
                "black"
            ]

            const html = document.documentElement;
            const htmlStyles = getComputedStyle(html);

            for (const color of colors) {
                this.changeColor(html, htmlStyles, color);
            }
        },
        toggleAside() {
            this.asideActive = !this.asideActive;
            this.toggleFocus();
        }
    },
    mounted() {
        window.addEventListener('resize', this.toggleFocus);
        this.toggleFocus();
    },
    unmounted() {
        window.removeEventListener('resize', this.toggleFocus);
    }
}
</script>

<template>
    <aside :class="asideActive ? 'visible' : ''">
        <sectionComponent sectionId="aside-menu">
            <button @click="toggleAside" class="aside-toggler">
                <img class="mask-icon" :style="`--mask-icon-url: url('${arrowIcon}')`" width="24" height="24"
                    :src="arrowIcon" :alt="asideActive ? 'Disable Aside' : 'Enable Aside'" />
            </button>
            <ul class="functions">
                <li>
                    <button @click="changeColorMode"
                        :class="[darkModeActive ? 'dark-mode' : 'light-mode', 'color-mode']">
                        <img class="mask-icon" :style="`--mask-icon-url: url('${darkModeActive ? sunIcon : moonIcon}')`"
                            width="24" height="24" :src="darkModeActive ? sunIcon : moonIcon"
                            :alt="darkModeActive ? 'Activate Light Mode' : 'Activate Dark Mode'" />
                    </button>
                </li>
            </ul>
        </sectionComponent>
    </aside>
</template>

<style lang="scss">
@use "sass:map";
@use "sass:color";
@import '@/styles/colors';
@import '@/styles/variables';
@import '@/styles/mixins';

aside {
    position: fixed;
    left: 0;
    top: 0;
    width: 4rem;
    height: 100vh; // safari can't do svh
    height: 100svh;
    z-index: 2;

    &:hover {
        #aside-menu {
            transform: translateX(0);
        }
    }

    #aside-menu {
        width: 100%;
        height: 100%;
        background: var(--black);
        padding: 0.75rem;
        transform: translateX(-100%);
        animation: appear 2s ease-out;
        transition: $transition-base;

        @keyframes appear {
            0% {
                transform: translateX(0);
            }

            90% {
                transform: translateX(0);
            }

            100% {
                transform: translateX(-100%);
            }
        }

        .functions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            height: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                .color-mode {
                    position: relative;
                    height: 2.5rem;
                    padding: 0.5rem;
                    background-color: map.get($colors, "full-black");
                    border: none;
                    box-shadow: $box-shadow;
                    border-radius: 0.25rem;
                    z-index: 1;
                    transition: $transition-base;

                    &:hover {
                        background-color: color.adjust(map.get($colors, "full-black"), $lightness: 5%);
                    }

                    .mask-icon {
                        width: auto;
                        height: 100%;
                        @include dynamic-background-color(var(--color-gradient, 3s));
                    }
                }
            }
        }

        .aside-toggler {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    aside {
        &:hover {
            #aside-menu {
                transform: translateX(-100%);
            }
        }

        &.visible {
            #aside-menu {
                transform: translateX(0);

                .aside-toggler {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }

        #aside-menu {
            .aside-toggler {
                display: block;
                position: absolute;
                right: -1.25rem;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
                background-color: unset;
                border: unset;
                transition: $transition-base;

                .mask-icon {
                    @include dynamic-background-color(var(--color-gradient), 3s);
                }
            }
        }
    }
}
</style>